import React from 'react'

const MainBanner = () => {
  return (
    <section className="conversational-ai-banner text-black vh-100">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-lg-6 order-last order-md-first text-center text-lg-start">
                    <h1>Scale Your Business with Multimodal Conversational AI</h1>
                    <p>Craft unique customer experiences across text and voice channels to improve NPS, cut support costs and boost top-line.</p>
                    <div><a className="btn btn-lg custom-btn" href="#">Request a demo</a></div>
                </div>
                <div className="col-12 col-lg-6 order-first order-md-last text-center"><img className="img-fluid" src="assets/img/conversational-ai/Main_LP_HI.png?h=681b8706d5f809df97cacdf2ba0d0c6e" /></div>
            </div>
        </div>
    </section>
  )
}

export default MainBanner