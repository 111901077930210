import React from "react";
import Slider from "react-slick";

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="slick-prev button-img">
        <img
          className="img-fluid"
          src="assets/img/prev-icon.png"
          onClick={onClick}
        />
      </button>
    );
  }
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button className="slick-next button-img">
        <img
          className="img-fluid"
          src="assets/img/next-icon.png"
          onClick={onClick}
        />
      </button>
    );
  }

const ExperienceSenseforthMob = () => {
    const ExperienceSenseforthMob = {
        dots: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        infinite: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
  return (
    <section className="pt-5 d-block d-md-none">
      <div className="container">
      <div className="col-12 col-md-6 offset-md-3 text-center"><h3 className="text-dark fc-h1-32" data-aos="fade" data-aos-duration="1000">Experience the <span className="custom-color">Senseforth.ai</span> Advantage</h3>
                <p className="fc-p-20" data-aos="fade" data-aos-duration="1000">Drive the next wave of growth with holistic Conversational AI solutions that enable you to reinvent customer acquisition, retention and support.</p>
            </div>
        <div className="row unlock-cx-mob">
          <div className="col-12 unlock-cx-slider pt-3 pb-5">
            <Slider {...ExperienceSenseforthMob}>
            <div>
              <img
                className="img-fluid"
                src="assets/img/conversational-ai/slide-1-mob-10x.png?h=43bc0918131bfc616c5fc1c0bc721284"
              />
            </div>
            <div>
              <img
                className="img-fluid"
                src="assets/img/conversational-ai/slide-2-mob-no-code.png?h=f634827f1a9a65991bf097aacab30d53"
              />
            </div>
            <div>
              <img
                className="img-fluid"
                src="assets/img/conversational-ai/slide-3-mob-patented.png?h=5ead84fc6f3ec97b4418550f760d4b46"
              />
            </div>
            <div>
              <img
                className="img-fluid"
                src="assets/img/conversational-ai/slide-4-mob-multichannel.png?h=0e8b05e2c5dc3d2f1d95bea13d2111da"
              />
            </div>
            <div>
              <img
                className="img-fluid"
                src="assets/img/conversational-ai/slide-5-mob-fullstack.png?h=a5840e319fa86a268d56ecd52b9e952e"
              />
            </div>
            <div>
              <img
                className="img-fluid"
                src="assets/img/conversational-ai/slide-6-mob-iso.png?h=2305b5a3197d7333c9a81016378dd26e"
              />
            </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExperienceSenseforthMob;
