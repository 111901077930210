import React from 'react'

const Gartner = () => {
  return (
<section className="py-5 gartner-mob gartner fc-light-grey-bg">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-7 order-last order-lg-first my-3"><h3 className="senseforth-blue" data-aos="fade" data-aos-duration="1000">Senseforth.ai <span className="weight-normal">recognized in 2022</span> Gartner<sup>®</sup> Magic Quadrant™  <span className="weight-normal ">for Enterprise Conversational AI Platforms</span></h3></div>
                <div className="col-12 col-lg-5 text-center order-first order-lg-last my-3"><img className="img-fluid" src="assets/img/conversational-ai/Gartner_logo.png?h=33228024c00ffc62c12972c995a0b1c0" /></div>
            </div>
        </div>
    </section>
  )
}

export default Gartner