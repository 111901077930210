import React, { useEffect } from "react";
import $ from 'jquery';

const AchieveFaster = () => {

  var repeat = 1;
  var toggleClassSel = 'invisible text-anim text-anim-current-slide';

  useEffect(() => {
    const interval = setInterval(function(){
      var textAnimSlides = $(".text-anim-slides");
      if(repeat == 3){
          textAnimSlides.each(function(index) {
             if($(this).hasClass('text-anim-current-slide')){
                 $(this).toggleClass(toggleClassSel);
                 if($(this).next().length) {
                    $(this).next().toggleClass(toggleClassSel);   
                 } else {
                     textAnimSlides.first().toggleClass(toggleClassSel);
                 }
                 return false;
             }
          });
          repeat=1;
      }
        textAnimSlides.toggleClass('animate');
      repeat++;
   },2000);
   return () => clearInterval(interval);

  }, []);

  return (
    <section className="bg-dark py-lg-5 achive-faster">
      <img
        className="d-md-none"
        src="assets/img/conversational-ai/section2-bottom-bg.png?h=d5b69c6f7ca7bd6a74f6201d57e11746"
      />
      <div className="container py-5 py-lg-0">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 order-last order-lg-first">
            <h2
              className="text-white achive-faster-h2 pt-5 pt-lg-0 aos-init"
              data-aos-duration="1000"
              data-aos="fade"
            >
              Achieve Faster <br /> Time-to-Value{" "}
              <span className="text-pink">
                <br /> with Pre-built AI Models{" "}
              </span>
            </h2>
            <p
              className="text-white achive-faster-p text-center text-lg-start aos-init"
              data-aos-duration="1000"
              data-aos="fade"
            >
              Launch smart virtual assistants in just a few days using pre-built
              and battle-tested AI models for{" "}
              <span className="text-pink font-mob-800">850+ use cases </span>{" "}
              across industry verticals and business functions.
            </p>
            <div className="drop-down-part">
              <p className="text-white achive-faster-p fc-italic-text-mob">
                What can I help you with?
              </p>
              <div className="dropdown">
                <button
                  className="btn btn-lg dropdown-toggle text-anim position-relative"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  type="button"
                >
                  <div className="text-anim-slides text-anim-current-slide text-anim">
                    <span>B</span>
                    <span>a</span>
                    <span>n</span>
                    <span>k</span>
                    <span>i</span>
                    <span>n</span>
                    <span>g</span>
                  </div>
                  <div className="text-anim-slides invisible">
                    <span>I</span>
                    <span>n</span>
                    <span>s</span>
                    <span>u</span>
                    <span>r</span>
                    <span>a</span>
                    <span>n</span>
                    <span>c</span>
                    <span>e</span>
                  </div>
                  <div className="text-anim-slides invisible">
                    <span>H</span>
                    <span>e</span>
                    <span>a</span>
                    <span>l</span>
                    <span>t</span>
                    <span>h</span>
                    <span>c</span>
                    <span>a</span>
                    <span>r</span>
                    <span>e</span>
                  </div>
                  <div className="text-anim-slides invisible">
                    <span>R</span>
                    <span>e</span>
                    <span>t</span>
                    <span>a</span>
                    <span>i</span>
                    <span>l</span>
                  </div>
                  <div className="text-anim-slides invisible">
                    <span>T</span>
                    <span>e</span>
                    <span>l</span>
                    <span>e</span>
                    <span>c</span>
                    <span>o</span>
                    <span>m</span>
                  </div>
                  <div className="text-anim-slides invisible">
                    <span>H</span>
                    <span>o</span>
                    <span>s</span>
                    <span>p</span>
                    <span>i</span>
                    <span>t</span>
                    <span>a</span>
                    <span>l</span>
                    <span>i</span>
                    <span>t</span>
                    <span>y</span>
                  </div>
                  <div className="text-anim-slides invisible">
                    <span>E</span>
                    <span>d</span>
                    <span>u</span>
                    <span>c</span>
                    <span>a</span>
                    <span>t</span>
                    <span>i</span>
                    <span>o</span>
                    <span>n</span>
                  </div>
                </button>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    Banking{" "}
                    <img
                      className="img-fluid"
                      src="assets/img/conversational-ai/arrow.png?h=92c960f6f5f537aaed9bd299206a32cb"
                    />
                  </a>
                  <a className="dropdown-item" href="#">
                    Insurance{" "}
                    <img
                      className="img-fluid"
                      src="assets/img/conversational-ai/arrow.png?h=92c960f6f5f537aaed9bd299206a32cb"
                    />
                  </a>
                  <a className="dropdown-item" href="#">
                    Healthcare{" "}
                    <img
                      className="img-fluid"
                      src="assets/img/conversational-ai/arrow.png?h=92c960f6f5f537aaed9bd299206a32cb"
                    />
                  </a>
                  <a className="dropdown-item" href="#">
                    Retail
                    <img
                      className="img-fluid"
                      src="assets/img/conversational-ai/arrow.png?h=92c960f6f5f537aaed9bd299206a32cb"
                    />
                  </a>
                  <a className="dropdown-item" href="#">
                    Telecom
                    <img
                      className="img-fluid"
                      src="assets/img/conversational-ai/arrow.png?h=92c960f6f5f537aaed9bd299206a32cb"
                    />
                  </a>
                  <a className="dropdown-item" href="#">
                    Hospitality
                    <img
                      className="img-fluid"
                      src="assets/img/conversational-ai/arrow.png?h=92c960f6f5f537aaed9bd299206a32cb"
                    />
                  </a>
                  <a className="dropdown-item" href="#">
                    Education
                    <img
                      className="img-fluid"
                      src="assets/img/conversational-ai/arrow.png?h=92c960f6f5f537aaed9bd299206a32cb"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 text-center order-first order-lg-last"
            data-aos="fade"
            data-aos-duration="1000"
          >
            <img
              className="img-fluid"
              src="assets/img/conversational-ai/Fold4_HI.png?h=43f3b8f01debaeb72481585f42a71c15"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AchieveFaster;
