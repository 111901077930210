import React from "react";

const ExperienceSenseforth = () => {
  return (
    <section
      id="home2-unlock-cx"
      className="pb-7 pt-7 unlock-cx d-none d-md-block"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="text-dark" data-aos="fade" data-aos-duration="1000">
              Experience the <span className="custom-color">Senseforth.ai</span>{" "}
              Advantage
            </h2>
            <p data-aos="fade" data-aos-duration="1000">
              Drive the next wave of growth with holistic Conversational AI
              solutions that
              <br /> enable you to reinvent customer acquisition, retention and
              support.
            </p>
          </div>
          <div
            className="col-12 col-md-4 py-3 card-col"
            data-aos="fade"
            data-aos-duration="1000"
          >
            <div className="card h-100 p-4">
              <div className="card-body">
                <img
                  className="img-fluid max-width img-1"
                  src="assets/img/conversational-ai/icon/FI_icon_hover.png?h=69cdd122560b707f968941a5ca35f098"
                />
                <img
                  className="img-fluid max-width img-2"
                  src="assets/img/conversational-ai/icon/FI_icon.png?h=d9145824f6a1025f0379f2136af68168"
                />
                <h4 className="card-title py-3">10x Faster <br/>Implementation</h4>
                <p>
                  Pre-built and battle-tested AI models makes digital assistants
                  smarter and reduces implementation time.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 py-3 card-col"
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <div className="card h-100 p-4">
              <div className="card-body">
                <img
                  className="img-fluid max-width img-1"
                  src="assets/img/conversational-ai/icon/NCC_ico.png?h=8ffed9e7e6080e18465c229c1deae9b1"
                />
                <img
                  className="img-fluid max-width img-2"
                  src="assets/img/conversational-ai/icon/NCC_ico_hover.png?h=9817cd188b273cfaa0f530de6494a7ba"
                />
                <h4 className="card-title py-3">No Code <br/>Conversational AI</h4>
                <p>
                  Speed up implementation with our proprietary zero-code
                  framework, built-in analytics, and pre-configured connectors.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 py-3 card-col"
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="card h-100 p-4">
              <div className="card-body">
                <img
                  className="img-fluid max-width img-1"
                  src="assets/img/conversational-ai/icon/NPL_ico.png?h=7d752be3a3b1dfb0a26fdffd7dd7bccd"
                />
                <img
                  className="img-fluid max-width img-2"
                  src="assets/img/conversational-ai/icon/NPL_ico_hover.png?h=d2ccf675e5f258a5131265d826488f6c"
                />
                <h4 className="card-title py-3">Patented <br/>NLP Tech</h4>
                <p>
                  Cutting-edge NLP technology that delivers ground-breaking
                  performance and industry-leading accuracy of over 96%.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 py-3 card-col"
            data-aos="fade"
            data-aos-duration="1000"
          >
            <div className="card h-100 p-4">
              <div className="card-body">
                <img
                  className="img-fluid max-width img-1"
                  src="assets/img/conversational-ai/icon/MCI_ico.png?h=6603958e1e75206253624c595b67f1a7"
                />
                <img
                  className="img-fluid max-width img-2"
                  src="assets/img/conversational-ai/icon/MCI_ico_hover.png?h=50fe7f5b02d3ccf0b14e0ce1a5a2b1b1"
                />
                <h4 className="card-title py-3">Multi-channel <br/>Experience</h4>
                <p>
                  Build once and deploy across multiple text and voice channels
                  such as websites, mobile apps, IMs, voice assistants and
                  telephony.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 py-3 card-col"
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <div className="card h-100 p-4">
              <div className="card-body">
                <img
                  className="img-fluid max-width img-1"
                  src="assets/img/conversational-ai/icon/FSS_ico.png?h=104df083669eb5b7c71aa6dcf1a51396"
                />
                <img
                  className="img-fluid max-width img-2"
                  src="assets/img/conversational-ai/icon/FSS_ico_hover.png?h=61b7bc05156b25e0ebf814ef7397c8f3"
                />
                <h4 className="card-title py-3">Full-Stack <br/>Solutions</h4>
                <p>
                  From building an AI Assistant to deploying it, Senseforth.ai
                  handles every aspect of creating an enterprise solution.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-4 py-3 card-col"
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="card h-100 p-4">
              <div className="card-body">
                <img
                  className="img-fluid max-width img-1"
                  src="assets/img/conversational-ai/icon/ISO_ico.png?h=514342fd6f894fe8ccf785aaf4bb49f6"
                />
                <img
                  className="img-fluid max-width img-2"
                  src="assets/img/conversational-ai/icon/ISO_ico_hover.png?h=69cdd122560b707f968941a5ca35f098"
                />
                <h4 className="card-title py-3">ISO 27001 <br/>Certified</h4>
                <p>
                  Senseforth.ai has a robust InfoSec Management Program and
                  abides by the globally recognized ISO 27001:2013 framework.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExperienceSenseforth;
