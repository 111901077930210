import React from "react";
import $ from "jquery";
import "./Styles.css";
import "./ConversationalAI.css";
import "./App.css";
import MainBanner from "./Components/ConversationalAI/MainBanner";
import ProgressCount from "./Components/ConversationalAI/ProgressCount";
import DriveBusiness from "./Components/ConversationalAI/DriveBusiness";
import AchieveFaster from "./Components/ConversationalAI/AchieveFaster";
import ScaleFaster from "./Components/ConversationalAI/ScaleFaster";
import ExperienceSenseforth from "./Components/ConversationalAI/ExperienceSenseforth";
import Gartner from "./Components/ConversationalAI/Gartner";
import TransformCX from "./Components/ConversationalAI/TransformCX";
import ExperienceSenseforthMob from "./Components/ConversationalAI/ExperienceSenseforthMob";
import DriveBusinessMob from "./Components/ConversationalAI/DriveBusinessMob";


function App() {
  return (
    <div className="App">
      <MainBanner/>
      <ProgressCount/>
      <DriveBusiness/>
      <DriveBusinessMob/>
      <AchieveFaster/>
      <ScaleFaster/>
      <ExperienceSenseforth/>
      <ExperienceSenseforthMob/>
      <Gartner/>
      <TransformCX/>
    </div>
  );
}

export default App;
