import React from 'react'

const TransformCX = () => {
  return (
    <section className="drive-better position-relative pt-5">
        <div className="drive-better-bg py-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center py-3 drive-better-content"><h2 data-aos="fade" data-aos-duration="1000">Transform CX To Scale With 360°<br className="d-non d-md-block"/>Conversational AI</h2>
                        <div data-aos="fade" data-aos-duration="1000" data-aos-delay="100"><a className="btn btn-lg custom-btn" href="#">Start Your Journey Now</a></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default TransformCX