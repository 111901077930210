import React from "react";

const ScaleFaster = () => {
  return (
    <section className="position-relative py-5 scale-faster">
      <div className="py-3 scale-faster-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center drive-better-content">
              <h2 data-aos="fade" data-aos-duration="1000" className="fc-h1-32">
                Scale Faster with a Complete
                <br className="d-non d-md-block" />
                Conversational AI Platform
              </h2>
              <p
                className="text-white"
                data-aos="fade"
                data-aos-duration="1000"
              >
                A.ware, a full-stack conversational AI platform that builds
                smart virtual assistants at a speed of light, <br /> with unique
                features and industry specific functionality.
              </p>
              <div
                data-aos="fade"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <a className="btn btn-lg custom-btn" href="#">
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScaleFaster;
