import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const ProgressCount = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <section className="conversational-ai-percent">
      <img
        className="img-fluid d-md-none"
        src="assets/img/conversational-ai/section1bg-cut.png?h=7d3064b20be1fb91bfd2326846f0e827"
      />
      <div className="container">
        <div className="row text-center py-5 desktop-counter">
          <div
            className="col-12 col-md-4 py-3 py-lg-0"
            data-aos="fade"
            data-aos-duration="1000"
          >
            <h2 className="default-h2">
              21%
              <img
                className="img-fluid ms-3"
                src="assets/img/Polygon%2013.png?h=0f174cabd264c38f5c9cfc48c29e8093"
              />
            </h2>
            <p className="loud-desc">Increase in Qualified Leads</p>
          </div>
          <div
            className="col-12 col-md-4 py-3 py-lg-0 dsk-counter-2"
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2 className="default-h2">
              35%
              <img
                className="img-fluid ms-3"
                src="assets/img/Polygon%2014.png?h=1c3f2215129d197577233779390023f9"
              />
            </h2>
            <p className="loud-desc">Reduction in Support Cost</p>
          </div>
          <div
            className="col-12 col-md-4 py-3 py-lg-0"
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <h2 className="default-h2">
              43%
              <img
                className="img-fluid ms-3"
                src="assets/img/Polygon%2013.png?h=0f174cabd264c38f5c9cfc48c29e8093"
              />
            </h2>
            <p className="loud-desc">Improvement in Containment Rate</p>
          </div>
        </div>
      </div>
      <img
        className="d-md-none"
        src="assets/img/conversational-ai/section2-top-bg.png?h=d53b5e7994af479e48c70b277dea5b50"
      />
    </section>
  );
};

export default ProgressCount;
