import React from 'react'

const DriveBusinessMob = () => {
  return (
<section id="deliver-mob-conversational-ai" className="d-block d-md-none py-5 pt-lg-3 deliver-mob-conversational-ai">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center pt-3"><h2>Drive Business Growth with <span className="custom-color">Conversational AI</span></h2></div>
            </div>
            <div className="row text-center pt-3">
                <div className="col-12 py-3">
                    <div className="accordion" role="tablist" id="accordion-2">
                        <div className="accordion-item text-start">
                            <h2 className="accordion-header" role="tab"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-2 .item-1" aria-expanded="true" aria-controls="accordion-2 .item-1">Acquire Customers</button></h2>
                            <div className="accordion-collapse collapse show item-1" role="tabpanel" data-bs-parent="#accordion-2">
                                <div className="accordion-body"><img className="img-fluid" src="assets/img/conversational-ai/Usecase1.png?h=809a407807ee4ad30c9cba72b0034ae1" />
                                    <p className="mb-0 pt-3">With Multilingual AI Virtual Assistants, you can not only engage with prospects and handle objections, but also capture leads and close sales on every channel.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item text-start">
                            <h2 className="accordion-header" role="tab"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-2 .item-2" aria-expanded="false" aria-controls="accordion-2 .item-2">Increased Engagement&nbsp;</button></h2>
                            <div className="accordion-collapse collapse item-2" role="tabpanel" data-bs-parent="#accordion-2">
                                <div className="accordion-body"><img className="img-fluid" src="assets/img/conversational-ai/Usecase2.png?h=9a6d08fcf5f93c6114fca6f6a253184d" />
                                    <p className="mb-0 pt-3">Provide relevant product recommendations based on customer needs or send personalized offers to increase lifetime value with omnichannel Virtual Assistants.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item text-start">
                            <h2 className="accordion-header" role="tab"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-2 .item-3" aria-expanded="false" aria-controls="accordion-2 .item-3">Provide 24x7 Support</button></h2>
                            <div className="accordion-collapse collapse item-3" role="tabpanel" data-bs-parent="#accordion-2">
                                <div className="accordion-body"><img className="img-fluid" src="assets/img/conversational-ai/Usecase3.png?h=fc55edd91fa9130ba5be008b6b735349" />
                                    <p className="mb-0 pt-3">Go beyond answering simple FAQs. Address complex issues, send real-time updates or documents and ensure your customer never have to wait for support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default DriveBusinessMob