import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const DriveBusiness = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <section className="d-none d-md-block deliver-dek-conversational-ai py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center py-3">
            <h2 data-aos="fade" data-aos-duration="1000" className="text-dark">
              Drive Business Growth with Conversational AI
            </h2>
          </div>
          <div className="col" data-aos="fade" data-aos-duration="1000">
            <div>
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    role="tab"
                    data-bs-toggle="tab"
                    href="#tab-1"
                  >
                    Acquire Customers
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    role="tab"
                    data-bs-toggle="tab"
                    href="#tab-2"
                  >
                    Increase Engagement
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    role="tab"
                    data-bs-toggle="tab"
                    href="#tab-3"
                  >
                    Provide 24x7 support
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" role="tabpanel" id="tab-1">
                  <div className="row align-items-center py-3">
                    <div className="col-12 col-md-6 offset-md-1 py-3">
                      <p>
                        With <b>Multilingual AI Virtual Assistants</b>, you can
                        not only engage with prospects and handle objections,
                        but also capture leads and close sales on every channel.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center py-3 deliver-dek-conversational-ai-img">
                      <img
                        className="img-fluid"
                        src="assets/img/conversational-ai/Usecase1.png?h=dd32bd8bc29a01ef653662e9774c941b"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" role="tabpanel" id="tab-2">
                  <div className="row align-items-center py-3">
                    <div className="col-12 col-md-6 offset-md-1 py-3">
                      <p>
                        Provide relevant product recommendations based on
                        customer needs or send personalized offers to{" "}
                        <b>
                          increase lifetime value with omnichannel Virtual
                          Assistants.
                        </b>
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center py-3 deliver-dek-conversational-ai-img">
                      <img
                        className="img-fluid"
                        src="assets/img/conversational-ai/Usecase2.png?h=5abf3c915f4591bdb57b0a907e942f0b"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" role="tabpanel" id="tab-3">
                  <div className="row align-items-center py-3">
                    <div className="col-12 col-md-6 offset-md-1 py-3">
                      <p>
                        <b>Go beyond answering simple FAQs.</b> Address complex
                        issues, send real-time updates or documents and ensure
                        your customer never have to wait for support.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center py-3 deliver-dek-conversational-ai-img">
                      <img
                        className="img-fluid"
                        src="assets/img/conversational-ai/Usecase3.png?h=891f30bdbe600da055b4fa95394948be"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DriveBusiness;
